const videos = [
    { src: require('~/assets/videos/video1.mp4'), liked: '200K', title: 'take hot trend' },
    { src: require('~/assets/videos/video2.mp4'), liked: '20K', title: 'yeah!! click your liked button' },
    { src: require('~/assets/videos/video3.mp4'), liked: '20M', title: 'Many things in life' },
    { src: require('~/assets/videos/video4.mp4'), liked: '15K', title: 'try hard for to' },
    { src: require('~/assets/videos/video5.mp4'), liked: '2M', title: 'take hot trend' },
    { src: require('~/assets/videos/video6.mp4'), liked: '97K', title: 'Do you remember' },
    { src: require('~/assets/videos/video7.mp4'), liked: '12.5M', title: 'friend in the world' },
    { src: require('~/assets/videos/video8.mp4'), liked: '25K', title: 'Hot trend 2023' },
    { src: require('~/assets/videos/video9.mp4'), liked: '54K', title: 'Do you like it' },
    { src: require('~/assets/videos/video10.mp4'), liked: '100K', title: 'Hot trend 2023' },
    { src: require('~/assets/videos/video11.mp4'), liked: '20K', title: 'Love you 3000' },
    { src: require('~/assets/videos/video12.mp4'), liked: '10K', title: 'yeah!! click your liked button' },
    { src: require('~/assets/videos/video13.mp4'), liked: '200', title: 'Try your best' },
    { src: require('~/assets/videos/video14.mp4'), liked: '150K', title: 'try hard for you' },
    { src: require('~/assets/videos/video15.mp4'), liked: '170', title: 'Take your hand up' },
    { src: require('~/assets/videos/video16.mp4'), liked: '90K', title: 'Do you remember our memory' },
    { src: require('~/assets/videos/video17.mp4'), liked: '1M', title: 'friend in the world' },
    { src: require('~/assets/videos/video18.mp4'), liked: '2K', title: 'Kiss the rain' },
    { src: require('~/assets/videos/video19.mp4'), liked: '5K', title: 'Do you like it' },
    { src: require('~/assets/videos/video20.mp4'), liked: '10K', title: 'Hot trend 2023' },
    { src: require('~/assets/videos/video21.mp4'), liked: '2K', title: 'Hey Hey! I love you' },
    { src: require('~/assets/videos/video22.mp4'), liked: '174K', title: 'yeah!! click your liked button' },
    { src: require('~/assets/videos/video23.mp4'), liked: '200', title: 'Many things in life' },
    { src: require('~/assets/videos/video24.mp4'), liked: '114', title: 'try hard for to' },
    { src: require('~/assets/videos/video25.mp4'), liked: '223', title: 'take your hand up' },
    { src: require('~/assets/videos/video26.mp4'), liked: '9K', title: 'Do you remember' },
];

export default videos;
